import { unsubscribe } from "../../js/path";

export default {
  data() {
    return {
      params: "",
      filter: null,
      pageNo: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      currentPage: 1,
      fields: [
        {
          key: "id",
        },
        {
          key: "name",
          class: "w-250",
          label: "Name",
        },
        {
          key: "join_date",
          class: "w-250",
          label: "Joining Date",
        },
        {
          key: "last_active_date",
          label: "Last Active Date",
        },
        {
          key: "unsubscribe",
          label: "Subscribe / Unsubscribe",
        },
      ],
      activeTab: "all",
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) {
        this.fetchData("search");
        this.currentPage = 1;
      } else if (this.filter.length == 0) {
        this.fetchData("search");
        this.currentPage = 1;
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = 1;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData("search");
          this.currentPage = 1;
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData("search");
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id == id);
        const url = unsubscribe?.updateUnsubscribeStatus + "/" + id;
        const data = await this.postRequest(url, {
          unsubscribe: !this.tableData.data[index].unsubscribe,
        });
        if (data.status) {
          const responseData = data.response;
          this.tableData.data[index] = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchData(pagination = null) {
      console.log(pagination);
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = unsubscribe?.getUnsubscribe;
        if (pagination) {
          url = `${url}?filter=all&search=${this.filter ?? ""}&page=${
            this.currentPage
          }`;
        }
        this.getUrl();
        url += this.params;
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data?.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err?.data ? err?.data?.message : "Please try again!",
        });
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      url.searchParams.has("filter")
        ? url.searchParams.set("filter", this.activeTab)
        : url.searchParams.append("filter", this.activeTab);
      if (this.filter != null && this.filter != "") {
        url.searchParams.has("search")
          ? url.searchParams.set("search", this.filter)
          : url.searchParams.append("search", this.filter);
      } else {
        url.searchParams.delete("search");
      }
      if (this.currentPage != null && this.currentPage != "") {
        url.searchParams.has("page")
          ? url.searchParams.set("page", this.currentPage)
          : url.searchParams.append("page", this.currentPage);
      } else {
        url.searchParams.set("page", 1);
      }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.query.filter) {
      this.activeTab = this.$route.query.filter;
    }
    if (this.$route.query.search) {
      this.filter = this.$route.query.search;
    }
    if (this.$route.query.page && this.$route.query.page != 1) {
      this.currentPage = parseInt(this.$route.query.page);
    } else {
      this.fetchData(this.activeTab);
    }
  },
};
